html {
  scroll-behavior: smooth;
}

.boxBorder {
  height: 100%;
  width: 100%;
  margin: 10px;
  border: 1px solid black;
}

.carouselBorder {
  height: 100%;
  margin: 10px;
  border: 1px solid black;
}

.boxOrange {
  background-color: lightsalmon;
}

.boxGreen {
  display: flex;
  align-items: center;
  background-color: lightgreen;
  text-align: center;
}

.boxBlue {
  display: flex;
  align-items: center;
  text-align: center;
  background-color: lightblue;
  padding: 10px;
}

.boxYellow {
  display: flex;
  align-items: center;
  background-color: lightgoldenrodyellow;
  padding: 10px;
  text-align: center;
  justify-content: center;
}

.boxBubbleMap {
  position: absolute;
  bottom: 5%;
  right: 5%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  border-radius: 10px;
}

.centerRow {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.itemImg {
  align-items: center;
  align-content: center;
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.mapImg {
  align-items: center;
  align-content: center;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.pageTitle {
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  width: max-content;
}

.pageImg {
  align-items: center;
  align-content: center;
  object-fit: cover;
  width: 100%;
  height: 110px;
}

.boxBubbleTop {
  position: absolute;
  top: 5%;
  justify-items: center;
  justify-content: center;
  width: 98.4%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 5px;
}

/* PC Screen Setting */
@media (min-width: 800px) {
  .carouselBorderTop {
    height: 100%;
    margin: 10px;
    border: 1px solid black;
  }

  .carouselImg {
    object-fit: cover;
    height: 75vh;
    width: 100%;
  }

  .boxCaroselTop {
    position: absolute;
    top: 15%;
    right: 5%;
    width: 35vw;
    height: fit-content;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px;
  }

  .boxCaroselBottom {
    position: absolute;
    bottom: 8%;
    right: 5%;
    width: 35vw;
    height: fit-content;
    text-align: center;
    background-color: rgba(255, 255, 125, 0.8);
    padding: 5px;
  }
}

/* Phone Screen Setting */
@media (max-width: 800px) {
  .carouselBorderTop {
    height: 100%;
    margin: 10px;
    border: 1px solid white;
  }

  .carouselImg {
    object-fit: cover;
    height: 60vh;
    width: 100%;
  }

  .boxCaroselTop {
    position: absolute;
    bottom: 5%;
    height: fit-content;
    width: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 5px;
    color: white;
  }

  .boxCaroselBottom {
    position: relative;
    margin-top: -10px;
    bottom: 0%;
    width: 100%;
    height: fit-content;
    text-align: center;
    background-color: rgba(255, 255, 125);
    padding: 5px;
  }
}
